<template>
  <div>
    <div
      class="record-main"
      v-for="(i, index) in data"
      :key="index"
      @click="toDetail(i)"
    >
      <div class="record-wrapper">
        <div class="word1">答题记录: {{ i.intime }}</div>
        <div :class="['word2', 'ty' + i.quScore]">
          {{ resultArrMap[i.quScore] }}
        </div>
      </div>
    </div>
    <v-empty v-if="data.length === 0"></v-empty>
  </div>
</template>
<script>
import { myQuListUrl } from "@/views/rubbishSort/api";
import { resultArrMap } from "@/views/rubbishSort/map";

export default {
  data() {
    return {
      data: [],
      id: "",
      resultArrMap,
      isLoading: false,
    };
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {
    this.id = this.$route.query.id;
  },
  mounted() {
    this.getAnswerRecord();
  },
  methods: {
    toDetail(item) {
      this.$router.push({
        name: "recordDetail",
        query: {
          id: item.id,
          topicId: this.id,
        },
      });
    },
    getAnswerRecord() {
      this.$axios
        .get(myQuListUrl, {
          params: {
            houseId: this.roomId,
            userId: this.userId,
            topicId: this.id,
            // houseId: "1007B1L1R102",
            // userId: "1473569728907198465 ",
            // topicId: this.id,
          },
        })
        .then((res) => {
          if (res.code === 200) {
            this.data = res.data;
          }
        });
    },
    // onRefresh() {},
  },
};
</script>
<style lang="less" scoped>
.record-main {
  background: #f9f9f9;
  box-shadow: 0 1px 4px 0 rgba(200, 200, 200, 0.5);
  border-radius: 6px;
  margin: 0 30px;
  margin-top: 32px;
  .record-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: PingFangSC-Regular, PingFang SC;

    .word1 {
      font-size: 32px;
      color: rgba(0, 0, 0, 0.85);
      padding: 48px 0;
      padding-left: 20px;
    }

    .word2 {
      padding-right: 30px;
      font-size: 32px;
      font-weight: 600;
      &.ty0 {
        color: #e50112;
      }
      &.ty1 {
        color: #e50112;
      }
      &.ty2 {
        color: #f8cc5c;
      }
      &.ty3 {
        color: #40a739;
      }
    }
  }
}
</style>
